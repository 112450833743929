import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { RegionProvider } from '../hooks/useRegion'
import Units from '../components/Units/Dedicated'

const UnidadesPage = ({ data, pageContext, location }) => {
  const unitsGatsbyPage = data.wordpressWpGatsbyPage.acf
  const settings = data.settings
  const unitsData = data.allWordpressAcfUnitsPage.edges

  return (
    <Layout pageContext={pageContext} location={location}>
      <SEO 
        title={`${unitsGatsbyPage.title} | ${settings.brand_name}`}
        description={unitsGatsbyPage.description}
        canonical={unitsGatsbyPage.canonical} 
      />
      <RegionProvider>
        <Units unitsData={unitsData} />
      </RegionProvider>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    wordpressWpGatsbyPage(slug: { eq: "unidades" }) {
      acf {
        title
        description
        canonical
      }
    }
    allWordpressAcfUnitsPage {
      edges {
        node {
          acf {
            unit_id
            unit_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            unit_name
            unit_state
            unit_video
          }
        }
      }
    }
    settings: wordpressAltaThemeSettings {
      brand_name
      brand_url
    }
  }
`

export default UnidadesPage
