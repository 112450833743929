import styled from 'styled-components'
import media from 'styled-media-query'

export const PageTitle = styled.h1`
  font-size: 4rem;
  font-weight: normal;
  line-height: 4.8rem;
  color: ${props => props.theme.colors.tertiary};
  margin: 0 0 4.8rem;

  ${media.lessThan('medium')`
    font-size: 3.2rem;
    line-height: 4rem;
    margin: 0 0 3.2rem;
  `}
`
