import styled from 'styled-components'
import media from 'styled-media-query'
import { Container } from '../../shared/container'

export const UnitsContainer = styled(Container)`
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;

  ${media.lessThan('medium')`
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  `}
`

export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2.7rem;
  min-height: 600px;

  ${media.greaterThan('huge')`
    grid-template-columns: repeat(4, 1fr);
  `}

  ${media.lessThan('medium')`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.lessThan('small')`
    grid-template-columns: repeat(1, 1fr);
  `}
`
