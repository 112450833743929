import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslations } from 'gatsby-plugin-translate'

import { UnitCard } from '../UnitCard'
import * as S from './styled'

const List = ({ unitsWpData, unitsDpData, stateCheckedValue }) => {
  const data = useStaticQuery(graphql`
    query {
      defaultImage: file(relativePath: { eq: "default.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const t = useTranslations()
  const translatedShowMore = t`Ver mais`

  const unitImageDefault = {
    imgFluid: [data.defaultImage.childImageSharp.fluid],
    imgAlt: 'unidade Alta',
  }

  const unitsListFilteredPerState = unitsDpData.filter(unit => unit.state === stateCheckedValue)

  const unitsImageList = unitsWpData.map(({ node: { acf: item } }) => item)

  return (
    <S.ListContainer>
      {unitsListFilteredPerState.map((unit, idx) => {
        const matchUnitImages = unitsImageList.filter(
          unitImage => unitImage.unit_id === unit.id.toString()
        )
        const matchedUnitImages = matchUnitImages?.map(
          img => img?.unit_image?.localFile?.childImageSharp?.fluid
        )

        const matchedUnitImage = {
          imgFluid: matchedUnitImages,
          imgAlt: matchUnitImages[0]?.unit_image?.alt_text || unitImageDefault.imgAlt,
        }

        const unitImage = [unitImageDefault, matchedUnitImage][Number(matchedUnitImages.length > 0)]

        return (
          <UnitCard
            key={unit.id}
            image={unitImage.imgFluid}
            alt={unitImage.imgAlt}
            name={unit.name}
            address={unit.simpleAddress}
            slug={unit.slug}
            label={translatedShowMore}
            isFirstImg={idx === 0}
            hasWhiteBg
          />
        )
      })}
    </S.ListContainer>
  )
}

export default List
