import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useRegion } from '../../../hooks/useRegion'
import { AllUnitsService } from '../../../utils/unityUtils'

import { useTranslations } from 'gatsby-plugin-translate'

import { PageTitle } from '../../shared/text-styled'
import StateFilter from '../StateFilter'
import filterOptions from '../filterOptions'
import List from './List'

import * as S from './styled'

const Units = ({ unitsData: unitsWpData }) => {
  const { getRegionOption } = useRegion()
  
  const data = useStaticQuery(graphql`
    {
      allUnities {
        nodes {
          id
          name
          address {
            number
            logradouro
            neighborhood
            zipCode
            city
            stateInitials
          }
        }
      }
    }
  `)
  
  const t = useTranslations()
  const translatedSectionTitle = t`Unidades`

  const [unitsDpData, setUnitsDpData] = useState([])
  
  useEffect(() => {
    setUnitsDpData(AllUnitsService(data))
  }, [])

  return (
    <S.UnitsContainer>
      <PageTitle>{translatedSectionTitle}</PageTitle>
      <StateFilter
        filterOptions={filterOptions}
        hasWhiteBg
      />
      <List
        unitsWpData={unitsWpData}
        unitsDpData={unitsDpData}
        stateCheckedValue={getRegionOption()}
      />
    </S.UnitsContainer>
  )
}

export default Units
